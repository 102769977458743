<template>
  <div>
    <form>
      <b-card>
        <h5 class="text-success mb-6">Thông tin nhóm</h5>
        <b-row>
          <b-col cols="4" style="min-height: 120px">
            <div class="image-upload">
              <v-file-input
                hide-input
                v-model="file"
                accept="image/png, image/jpeg"
                class="icon-edit"
                @change="onUpload"
                prepend-icon="mdi-pencil-outline"
                truncate-length="15"
              ></v-file-input>
              <v-img :src="preview" :aspect-ratio="2 / 1"></v-img>
            </div>
            <div class="error text-error">
              <p v-if="!isImageValid" class="mb-1">
                Kích thước ảnh tối đa nhỏ hơn 512kb
              </p>
              <p v-if="!isImage" class="mb-1">
                Định dạng ảnh phải là JPG hoặc PNG
              </p>
            </div>
            <label
              class="d-flex flex-column align-items-center justify-content-center"
              >Hình ảnh nhóm, tỷ lệ 2:1
              <span class="d-block">
                Tối đa 512kb, kích thước đề xuất 640:320px (tỷ lệ 2:1)</span
              ></label
            >
          </b-col>
          <b-col>
            <b-row>
              <b-col>
                <basic-input
                  :required="true"
                  label="Mã nhóm"
                  name="code"
                  v-validate="'required|alpha_num|max:10'"
                  :state="validateState('code')"
                  :invalidFeedback="errors.first('code')"
                  data-vv-as="Mã nhóm"
                  placeholder="Nhập mã nhóm"
                  v-model="form.code"
                  maxlength="10"
                />
              </b-col>
              <b-col>
                <basic-select
                  v-validate="'required'"
                  :required="true"
                  label="Coach"
                  placeholder="--- Chọn coach ---"
                  name="coach"
                  :solid="false"
                  :allowEmpty="true"
                  :state="validateState('coach')"
                  :invalidFeedback="errors.first('coach')"
                  :value.sync="form.coach"
                  :options="coaches"
                  value-label="text"
                  track-by="value"
                  changeValueByObject
                  @update:value="$validator.reset({ name: 'coach' })"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <basic-input
                  :required="true"
                  label="Tên nhóm"
                  name="name"
                  v-validate="'required|max:100'"
                  :state="validateState('name')"
                  :invalidFeedback="errors.first('name')"
                  data-vv-as="Tên nhóm"
                  placeholder="Nhập tên nhóm"
                  v-model="form.name"
                  maxlength="100"
                />
              </b-col>
              <b-col cols="6">
                <basic-input
                  :required="true"
                  label="Số lượng"
                  name="maxMember"
                  v-validate="{
                    required: true,
                    regex: REGEX.IS_NUMBER,
                    min_value: 1,
                  }"
                  :state="validateState('maxMember')"
                  :invalidFeedback="errors.first('maxMember')"
                  data-vv-as="Số lượng"
                  placeholder="Nhập số lượng"
                  v-model="form.maxMember"
                  input-number
                />
              </b-col>
              <b-col cols="6">
                <basic-input
                  :required="true"
                  label="Link nhóm chat"
                  name="link"
                  v-validate="{
                    required: true,
                    valid_link_zalo: true,
                    max: 2048,
                  }"
                  :state="validateState('link')"
                  :invalidFeedback="errors.first('link')"
                  data-vv-as="Link nhóm chat"
                  placeholder="Nhập link nhóm chat"
                  v-model="form.linkZalo"
                  maxlength="2048"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </form>
    <b-card v-if="showPatient" class="mt-6">
      <div class="d-flex justify-content-between align-items-center my-5">
        <h5 class="text-success">Khách hàng</h5>
        <b-button
          v-if="showBtnAddPatient"
          class="btn btn-success"
          type="button"
          @click="addPatient"
        >
          Thêm khác hàng
        </b-button>
      </div>
      <TablePatient
        :paging="paging"
        :patients="selectedPatients"
        @delete="deletePatient"
        @change-group="changeGroup"
        @sortBy="sortBy"
      />
    </b-card>
    <ModalAddPatient
      v-if="showModalAdd"
      :max-member="form.maxMember"
      :show="showModalAdd"
      @reset="resetModalAdd"
      @open-modal-mapping="openModalMapping"
    />
    <ModalChangeGroup
      v-if="showModalChange"
      :show="showModalChange"
      :id="patientId"
      @reset="resetModalChangeGroup"
      @open-modal-mapping="openModalMapping"
    />
    <ModalMappingValue
      :customerId="
        (selectedCustomer && selectedCustomer.accountId) || patientId
      "
      :groupId="(selectedGroup && selectedGroup.id) || id"
      @reset="resetModalMapping"
    />
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import {
  DELETE_PATIENTS,
  SORT_PATIENT,
} from '@/core/services/store/coach.module';
import { REGEX } from '@/core/plugins/constants';
import differenceBy from 'lodash/differenceBy';
const { mapState, mapActions } = createNamespacedHelpers('coach');
export default {
  name: 'FormCoach',
  components: {
    TablePatient: () => import('./TablePatient.vue'),
    ModalAddPatient: () => import('./ModalAddPatient.vue'),
    ModalChangeGroup: () => import('./ModalChangeGroup.vue'),
    ModalMappingValue: () =>
      import('../../active_account/components/ModalMappingValue.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
      isImageValid: true,
      isImage: true,
      preview: null,
      REGEX,
      form: {
        code: null,
        name: null,
        coach: null,
        maxMember: null,
        linkZalo: null,
      },
      paging: {
        page: 1,
        pageSize: 5,
        total: 10,
      },
      patientId: null,
      showModalAdd: false,
      showModalChange: false,
      selectedCustomer: null,
      selectedGroup: null,
    };
  },
  computed: {
    ...mapState(['selectedPatients', 'coaches', 'groupDetail']),
    showPatient() {
      return Number(this.form.maxMember) > 0;
    },
    showBtnAddPatient() {
      return this.selectedPatients.length < Number(this.form.maxMember);
    },
  },
  watch: {
    groupDetail: {
      handler(val) {
        if (!val) return;
        this.setupData();
      },
      deep: true,
    },
  },
  created() {
    this.setupData();
  },
  methods: {
    ...mapActions({ DELETE_PATIENTS, SORT_PATIENT }),
    setupData() {
      if (this.id && this.isUpdate && this.groupDetail) {
        this.form = { ...this.groupDetail };
        this.preview = this.groupDetail.image.url || null;
        this.form.coach = this.coaches.find(
          (el) => el.value === this.groupDetail.accountId,
        );
      }
    },
    onUpload() {
      this.isImage = ['image/jpeg', 'image/png'].includes(this.file.type);
      this.isImageValid = this.file.size <= 512000;
      if (this.isImage && this.isImageValid) {
        return (this.preview = URL.createObjectURL(this.file));
      }
      return (this.preview = null);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result || !this.isImageValid || !this.isImage) {
          return;
        }
        this.submit();
      });
    },
    submit() {
      const params = {
        code: this.form.code,
        name: this.form.name,
        maxMember: Number(this.form.maxMember),
        accountId: this.form.coach.value,
        linkZalo: this.form.linkZalo,
      };

      if (Number(this.form.maxMember) < this.selectedPatients.length) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Số lượng khách hàng đã quá số lượng cho phép',
          preventDuplicates: true,
        });
      }

      if (!this.isUpdate) {
        // create
        params['patientIdList'] = this.selectedPatients.map((el) => el.id);
      } else {
        // update
        params['id'] = this.id;
        this.groupDetail.coverId &&
          (params['coverId'] = this.groupDetail.coverId);
        const additionalPatients = differenceBy(
          this.selectedPatients,
          this.groupDetail.patients,
          'id',
        );
        additionalPatients.length &&
          (params['patientIdList'] = additionalPatients.map((el) => el.id));
      }

      this.file && (params['file'] = this.file);

      this.$emit('submit', params);
    },
    addPatient() {
      this.showModalAdd = true;
    },
    resetModalAdd() {
      this.showModalAdd = false;
    },
    deletePatient(params) {
      const patients = this.selectedPatients.filter(
        (el) => el.id !== params.id,
      );
      this.DELETE_PATIENTS({
        selectedPatients: patients,
      });
    },
    changeGroup(item) {
      this.patientId = item.id;
      this.showModalChange = true;
    },
    resetModalChangeGroup() {
      this.patientId = null;
      this.showModalChange = false;
    },
    sortBy(params) {
      this.SORT_PATIENT(params);
    },
    async openModalMapping({ type, item }) {
      if (type === 'customer') {
        this.selectedCustomer = item;
        this.showModalAdd = false;
      } else {
        this.selectedGroup = item;
        this.showModalChange = false;
      }
      await this.delay(50);
      this.$bvModal.show('modal-mapping-value');
    },
    resetModalMapping() {
      if (this.selectedCustomer) {
        this.showModalAdd = true;
        this.selectedCustomer = null;
      } else {
        this.showModalChange = true;
        this.selectedGroup = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.image-upload {
  width: 240px;
  height: 120px;
  background-color: #f3f6f9;
  box-shadow: 0px 4px 4px 0px #000000 10%;
  position: relative;
  margin: auto;
}
.text-error {
  color: #ff5756;
  text-align: center;
  margin-top: 20px;
}
.pictureInput {
  position: relative;
}
.icon-edit {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 18px);
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}
</style>
